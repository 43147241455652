// import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';

// import { Card } from '../components/common/card/card';
import { GroupAnimation, GroupedBlockAnimation } from '../components/animations/group-animation';
import { Metadata } from '../components/core/metadata';
import { create } from '../helpers/bem';

import './articles.scss';

const bem = create('page-articles');

const ArticlesPage = () => {
  /* const {
    allMdx: { edges },
  } = useStaticQuery(graphql`
    query {
      allMdx(
        sort: {fields: frontmatter___date, order: DESC}
        filter: {fileAbsolutePath: {regex: "/(articles)/.*\\.mdx$/"}}
      ) {
        edges {
          node {
            id
            slug
            frontmatter {
              title
              intro
              date
            }
          }
        }
      }
    }
  `); */
  const { t } = useTranslation();

  return (
    <Metadata
      title={t('Articles')}
      description="Articles written by Frontend Developer Yves Rijckaert."
    >
      <GroupAnimation as="section" className={bem()}>
        <GroupedBlockAnimation delay={500} as="h2" className={bem('headline')}>
          <span>Coming soon</span>
        </GroupedBlockAnimation>
      </GroupAnimation>
      {/* <section className={bem()}>
        <h2 className={bem('headline')}>{t('Articles')}</h2>
        <ol className={bem('list')}>
          {edges.map(({
            node: { slug, id, frontmatter: { title, intro, date } },
          }) => (
            <li key={id} className={bem('list-item')}>
              <Card
                title={title}
                link={slug}
                intro={intro}
                variant="Article"
                date={date}
              />
            </li>
          ))}
        </ol>
      </section> */}
    </Metadata>
  );
};

export default ArticlesPage;
